import { createWebHistory, createRouter } from "vue-router";
import { useAuthStore } from '@/stores';

const routes = [
    {
        path: "/app/landing",
        name: "Home",
        component: () => import('./views/index')
    },
    {
        path: "/app/resultPay",
        name: "ResultPay",
        component: () => import('./views/app/resultPay')
    },
    {
        path: "/app/connectionUser",
        name: "ConnectionUser",
        component: () => import('./views/app/connectionUser')
    },
    {
        path: "/app/taobaoUser",
        name: "taobaoUser",
        component: () => import('./views/app/taobaoUser')
    },
    {
        path: "/",
        name: "Home",
        component: () => import('./views/index')
    },
    {
        path: "/app/index",
        name: "Main",
        component: () => import('./views/app/index')
    },
    {
        path: "/app/plan",
        name: "Plan",
        component: () => import('./views/app/plan')
    },
    {
        path: "/user/login",
        name: "Login",
        component: () => import('./views/user/login')
    },
    {
        path: "/user/authPhone",
        name: "authPhone",
        component: () => import('./views/user/authPhone')
    },
    {
        path: "/user/Modal/CheckplusError",
        name: "CheckplusError",
        component: () => import('./views/user/Modal/CheckplusError')
    },
    {
        path: "/user/Modal/CheckplusSuccess",
        name: "CheckplusSuccess",
        component: () => import('./views/user/Modal/CheckplusSuccess')
    },
    {
        path: "/user/register",
        name: "register",
        component: () => import('./views/user/register')
    },
    {
        path: "/user/termsOfservice",
        name: "termsOfservice",
        component: () => import('./views/user/termsOfservice')
    },
    {
        path: "/user/privacyPolicy",
        name: "privacyPolicy",
        component: () => import('./views/user/privacyPolicy')
    }
];


export const router = createRouter({
    history: createWebHistory(),
    routes,
});



router.beforeEach(async (to) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/api','/user/login','/','/app/landing','/user/authPhone','/user/Modal/CheckplusError','/user/Modal/CheckplusSuccess','/user/register','/user/privacyPolicy','/user/termsOfservice'];
    const authRequired = !publicPages.includes(to.path);
    const auth = useAuthStore();

    if (authRequired && !auth.accessToken) {
        auth.returnUrl = to.fullPath;
        return '/user/login';
    }
});