import { createPinia } from 'pinia'
import { createApp } from 'vue'
import { router } from './router' // 라우터 추가하고
import App from './App.vue'


const app = createApp(App);

import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import globalMixins from './mixins/globals';
import Popper from "vue3-popper";
import Loading from 'vue-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import BootstrapVue3 from 'bootstrap-vue-3'
// import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

app.use(BootstrapVue3)
app.use(Loading.Plugin)
app.use(globalMixins)
app.use(VueSweetalert2)
app.use(LoadingPlugin)
app.use(router) // 사용 설정 하기
app.use(createPinia())
app.component('Popper',Popper)
app.mount('#app')