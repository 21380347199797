import {RegisterJwtAxios} from "@/packaging";

export const registerAPI = {
    async ChecksumMasterCode(code){
        const responseModel = await RegisterJwtAxios.get(`/Account/ChecksumMasterCode?code=${code}`);
        return responseModel
    },
    async ChecksumRecommenderCode(code){
        const responseModel = await RegisterJwtAxios.get(`/Account/ChecksumRecommenderCode?code=${code}`);
        return responseModel
    },
    async CheckAccount(account){
        const responseModel = await RegisterJwtAxios.getPost(`/Account/CheckAccount`,account);
        return responseModel
    },
    async UserCreate(model){
        const responseModel = await RegisterJwtAxios.post(`/Account/UserCreate`,model);
        return responseModel
    },
}