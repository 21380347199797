import axios from "axios";
import { useAuthStore } from '@/stores';

export const RegisterJwtAxios = {
    get: RegisterRequest('GET'),
    post: RegisterRequest('POST'),
    getPost: GETPOST('POST'),
};

function RegisterRequest(method) {
    return (path, body) => {
        let url = process.env.VUE_APP_API_URI + path

        const requestOptions = {
            method,
            headers: authHeader()
        };

        if(method == 'GET' && body){
            url += "?" + jsonToQuery(body)
        }else if ((method=='POST' || method=='PUT' || method=='DELETE' || method=='PATCH') && body) {
            requestOptions.headers['Content-Type'] = 'application/json charset=utf-8';
            requestOptions.body = JSON.stringify(body);
        }


        return axios({
            method,
            url,
            data:body,
            headers: requestOptions.headers,
        }).then(handleResponse).catch(handleError);
    }
}
function GETPOST(method) {
    return (path, body) => {
        let url = process.env.VUE_APP_API_URI + path

        const requestOptions = {
            method,
            headers: authHeader()
        };

        requestOptions.headers['Content-Type'] = 'application/json; charset=utf-8';
        requestOptions.body = body;



        return axios({
            method,
            url,
            data:body,
            headers: requestOptions.headers
        }).then(handleResponse).catch(handleError);
    }
}


function authHeader() {
    //return authheader with jwt if user is logged in and request is to the api url
    const { accessToken } = useAuthStore();

    if (accessToken != null) {
        return { Authorization: `Bearer ${accessToken}` };
    } else {
        return {};
    }
}

function handleResponse(response) {
    const data = response.data

    if (response.status != 200) {
        const { refreshToken, logout } = useAuthStore();
        if ([401, 403].includes(response.status) && refreshToken) {
            logout();
        }

        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    }

    return data;
}

function handleError(error){
    let response = error.response
    if( response == undefined) { return false }

    let data = response.data
    if ([401, 403].includes(response.status)) {
        const { refreshToken, logout } = useAuthStore();
        if (refreshToken) {
            logout();
        }
    }

    return data
}

function jsonToQuery(param) {
    if (typeof param != "object") {
        return false
    }

    var str = ""
    for (var key in param) {
        str += `${key}=${param[key]}&`
    }

    return str
}